import { createContext, useContext, useState } from "react"
import { ICampanha } from "."


interface IOtimizacaoScreenContextState {
    campanhas: ICampanha[]
    setCampanhas: (value: ICampanha[]) => void

}

export const OtimizacaoScreenContext = createContext<IOtimizacaoScreenContextState>({} as IOtimizacaoScreenContextState)

export function useOtimizacaoScreenContext(): IOtimizacaoScreenContextState {
    const context = useContext(OtimizacaoScreenContext)
    return context
}

export function OtimizacaoContextProvider({ children }: any) {
    const [campanhas, setCampanhas] = useState([] as ICampanha[]);

    return (
        <OtimizacaoScreenContext.Provider value={{
            campanhas, setCampanhas,
        }}>
            {children}
        </OtimizacaoScreenContext.Provider>
    )

}