import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

interface TProps {
  valor: number
}

function formatLabel(valor: any) {
  console.log('valor')
  console.log(valor)
  if (valor === 10) {
    return "Ruim"; // ruim
  } else if (valor === 30) {
    return "Médio"; // medio
  } else if (valor === 60) {
    return "Bom"; // bom
  } else if (valor === 90) {
    return "Ótimo"; // otimo
  } 
  return "valor: " + valor;
}

export default function DiscreteSliderValues(props: TProps) {
  return (
    <Box sx={{ width: 100 }}>
      <Slider
        defaultValue={props.valor}
        valueLabelDisplay="auto"
        style={{ color: "#BBB" }}
        valueLabelFormat={formatLabel}

      />
    </Box>
  );
}