import { useContext, useState } from 'react';
import '../../App.css';
import { Grid, Paper, Typography } from '@mui/material';
import {
  BRANCO, LARANJA, ROXO,
  inicianteDireita, inicianteDireitaSelecionado, inicianteEsquerda,
  inicianteEsquerdaSelecionado, paperNaoSelecionado, paperSelecionado
} from './styles';
import { useGlobalContext } from '../Context';

export default function Iniciante() {
  const [inicianteMouseOver, setInicianteMouseOver] = useState(false);
  const { clicked, setClicked, showStep, setShowStep } = useGlobalContext();

  const expandido = {
    alignItems: "center",
    borderRadius: 10,
    marginRight: 40,
    width: 700,
    height: 340,
    padding: 20,
    marginTop: -20,
    cursor: "pointer",
    backgroundColor: LARANJA,
    color: "#FFF",
    zIndex: 9999,
  };

  const selectIniciante = () => {
    setClicked(clicked);
    setShowStep("INICIANTE");
  }

  return (
    <>
      {!clicked && (showStep === "INICIANTE" || showStep === "") ?
        <Grid item
          onClick={selectIniciante}
          onMouseOver={() => setInicianteMouseOver(true)}
          onMouseLeave={() => setInicianteMouseOver(false)}>
          <Paper style={inicianteMouseOver  || showStep === "INICIANTE" ? paperSelecionado : paperNaoSelecionado}>

            <Grid container style={{ marginLeft: 40 }}>

              <Grid container spacing={1} >
                <Grid item style={{
                  height: "130px",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}>
                  <div style={inicianteMouseOver  || showStep === "INICIANTE" ? inicianteEsquerdaSelecionado : inicianteEsquerda} />
                </Grid>
                <Grid item style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center"
                }}>
                  <div style={inicianteMouseOver || showStep === "INICIANTE" ? inicianteDireitaSelecionado : inicianteDireita} />
                </Grid>
              </Grid>

              <Grid container style={{ marginTop: 4 }}>
                <Typography style={
                  inicianteMouseOver || showStep === "INICIANTE" ?
                    { color: BRANCO, fontSize: 18, fontWeight: 700 } :
                    { color: ROXO, fontSize: 18, fontWeight: 700 }
                }>
                  INICIANTE
                </Typography>
              </Grid>

              <Grid container>
                <Typography style={
                  inicianteMouseOver ?
                    { color: BRANCO, fontSize: 14, fontWeight: 400, fontStyle: "italic" } :
                    { color: ROXO, fontSize: 14, fontWeight: 400, fontStyle: "italic" }
                }>
                  Recomendado {clicked}
                </Typography>
              </Grid>

              {/* texto */}
              <Grid container>
                <Grid item>
                  <Typography style={{ fontSize: 12, width: 160, textAlign: "center", marginLeft: -20 }}>
                    Aprenda a usar todos os recursos da G Growth com o nosso onboarding
                  </Typography>
                </Grid>
              </Grid>

            </Grid>

          </Paper>
        </Grid> :
        <></>
        // <Grid item onClick={() => setClicked(!clicked)} >
        //   <Paper style={expandido}>
        //     <Typography>
        //       Testeeee {`${clicked}`}
        //     </Typography>
        //   </Paper>
        // </Grid>
      }

    </>
  );
}
