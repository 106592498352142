import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { LARANJA } from '../../../Maturidade/Iniciante/styles';
import { BRANCO } from '../../../Cadastro/styles';

export const AccordionCampanha = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
    border: '0px solid',
    marginBottom: 10,

    '&:before': {
        display: 'none',
    },
}));


export const AccordionCampanhaResumo = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<KeyboardArrowUp style={{ color: BRANCO, backgroundColor: LARANJA, borderRadius: "50%" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: BRANCO,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        color: "#FFF",
    },
    borderRadius: 10,
    minHeight: 60,
    height: 60,
}));


export const AccordionCampanhaDetalhes = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    border: '0px solid',
}));