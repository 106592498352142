import React, { useState } from 'react';
import '../App.css';
import background from '../../src/ggrowth-maturidade.png';
import { Avatar, Button, CssBaseline, Grid, Input, InputAdornment, InputLabel, Paper, TextField, Typography } from '@mui/material';
import StyledMenu from '../GGComponents/GGMenuUsuario';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Iniciante from './Iniciante';
import { GlobalContextProvider, useGlobalContext } from './Context';
import Intermediario from './Intermediario';
import Avancado from './Avancado';
import Rodape from './Rodape';


export default function Maturidade({ setAtual }: any) {

  const ROXO = '#7822DB';
  const LARANJA = '#F69F61';
  const { clicked, setClicked } = useGlobalContext();

  return (
    <div style={{
      backgroundImage: `url(${background})`, height: "100vh",
      backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"
    }}>
      <CssBaseline />
      <Grid container style={{ paddingLeft: 240, paddingTop: 80, height: "100vh" }} >
         {/* menu do usuario */}
        <Grid item container xs={12} lg={12} justifyContent={"right"} style={{ marginTop: -40, marginRight: 24 }}>
          <Grid item >
            <StyledMenu />
          </Grid>
          <Grid item>
            <Avatar style={{ backgroundColor: ROXO}}>
            </Avatar>
          </Grid>
        </Grid>

        {/* cards de maturidade */}
        
        <GlobalContextProvider>
          <Iniciante/>
          <Intermediario setAtual={setAtual} />
          <Avancado />
          <Rodape />
          
        </GlobalContextProvider>


      </Grid>

    </div>
  );
}
