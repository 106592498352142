import { Chip } from "@mui/material";

interface IndicadorChip {
  descricao: string,
  valor: string
}
interface TProps {
  chip: IndicadorChip[]
}

const AZUL = "#00F";
const VERMELHO = "#F00";

export default function IndicadoresChips(indicadorChip: TProps) {
  return (
    <>
      {indicadorChip.chip?.map((indicador) => (
        <Chip style={
          { 
            backgroundColor: "#FFF", marginRight: 10, height: 26, marginBottom: 10,
            boxShadow: "inset 0px 5px 7px 1px rgba(0, 0, 0, 0.25)", border: "3px solid #7822DB" 
          }} 
          label={
          <span>
            {/* <span style={{ fontWeight: 400, fontSize: 10, color: "#000" }}>
              {indicador.descricao}:
            </span> */}
            <span style={{ fontWeight: 700, color: "#000" }}> {indicador.valor}</span>
          </span>} 
        />
      ))}
    </>
  )
}