import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Campanha from './Campanha';
import { UsuarioMenu } from './UsuarioMenu';
import { FiltrosdeBusca } from './FiltrosdeBusca';
import { ROXO } from '../Maturidade/Iniciante/styles';
import ModalEscolhaFerramenta from './ModalEscolhaFerramenta';
import { Backdrop, Button, Chip, CircularProgress, Typography } from '@mui/material';
import { LARANJA } from '../Cadastro/styles';
import React, { useEffect, useState } from 'react';
import { OtimizacaoContextProvider, OtimizacaoScreenContext, useOtimizacaoScreenContext } from './OtimizacaoScreenContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DashboardGoogleAds } from './DashboardGoogleAds';
import { mockCampanhas } from './mockCampanhas';
import FileUpload from './FileUpload';
import CampanhaResumo from './Campanha/CampanhaResumo';


export interface ICampanha {
  titulo: string,
  indicadoresChips: any[
  //   {
  //   indicador: string,
  //   valor: string
  // }
  ],
  etapaDoFunil: string,
  investimento: string,
  indicadoresCheckboxes: any[
  // {
  //   indicador: string,
  //   valor: boolean
  // },
  ],
  dataInicio: string
  dataFim: string,
  conjuntos: any[] //aqui vai ser conjuntosAnuncios
  // trazer o Model do Backend pra cah

}
const dtosDeCampanhasDaAPI = mockCampanhas;

const TITULO_CARD_CAMPANHA: React.CSSProperties = {
  color: "#7822DB", backgroundColor: "#f5f5f5",
  fontSize: 16, fontWeight: 700, textTransform: "uppercase"
};

export default function OtimizacaoScreen() {
  const [showCampanhas, setShowCampanhas] = useState(true);
  const { campanhas } = useOtimizacaoScreenContext();

  return (
    <Container>
      <Grid container spacing={2} >

        <Grid item lg={10}>
          {/* <DashboardGoogleAds /> */}
        </Grid>
        <Grid item lg={2} md={2}>
         
        </Grid>

        <FileUpload/>

        {/* <Backdrop open={!showCampanhas} style={{ zIndex: 9999, backgroundColor: "rgba(246, 159, 97, 0.6)" }}>
          <ModalEscolhaFerramenta showCampanhas={showCampanhas} setShowCampanhas={setShowCampanhas} />
        </Backdrop> */}

        {showCampanhas && campanhas ?
          <>
            <Grid item xs={12}>

              <Grid md={12}>
                <Typography style={TITULO_CARD_CAMPANHA} >
                  Campanhas
                </Typography>
              </Grid>

              {/* rotulos */}
              <Grid container md={12} style={{ marginBottom: 10, marginLeft: 30 }}>
                <Grid md={4}>
                </Grid> 
                <>   
                  {["Tipo de resultado","Resultado","CTR","Alcance","Valor usado"].map((header) => (
                    <Chip   
                      label={
                        <span style={{ fontWeight: 700, color: "#000", marginRight: 5 }}> 
                          {header}
                        </span>}
                    />
                  ))}
                </>
              </Grid>

              <Grid md={12}>
                {campanhas?.filter((item, i) => i > 0).map((campanha) => (
                  // <Campanha campanha={campanha} />
                  <CampanhaResumo campanha={campanha}/>
                ))}
              </Grid>
            </Grid>
          </> :
          <></>
        }

      </Grid>

    </Container>
  );
}