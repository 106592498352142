import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Backdrop, Button, CircularProgress, Grid, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import Dashboard from '../Dashboard/Dashboard';
import OtimizacaoScreen from '../OtimizacaoScreen';
import Vinculo from '../Vinculo';
import Perfil from '../Perfil';
import { AppBar } from './AppBar';
import NovaCampanha from '../NovaCampanha';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import ggrowthLogo from "../ggrowth-logo.png";
import Maturidade from '../Maturidade';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShapeLineIcon from '@mui/icons-material/ShapeLine';
import ScienceIcon from '@mui/icons-material/Science';
import { OtimizacaoContextProvider } from '../OtimizacaoScreen/OtimizacaoScreenContext';
import VinculoFacebook from '../VinculoFacebook';
import FileUpload from '../OtimizacaoScreen/FileUpload';

const drawerWidth: number = 200;
const ROXO = '#7822DB';
const LARANJA = '#F69F61';
const FUNDO_BRANCO = "#F5f5f5";

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop: any) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      backgroundColor: ROXO,
      color: "#FFF",
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      borderRight: '0px',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const defaultTheme = createTheme();


export default function Portal() {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [atual, setAtual] = React.useState("portal");
  const [open, setOpen] = React.useState(true);
  const [step, setStep] = React.useState("4");
  const [showBackdrop, setShowBackdrop] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  // useEffect(() => {
  //   const onboarding = searchParams.get("onboarding");
  //   const step1 = searchParams.get("step");
  //   setStep(`${step1}`);

  //   console.log(`onboarding ${onboarding}`);
  //   console.log(`step ${step1}`);

  //   if (onboarding) {
  //     setAtual("vinculo");
  //   }
  //   if (step1 == "3") {
  //     setAtual("configuracao")
  //   }
  //   if (step1 == "4") {
  //     setAtual("otimizacao")
  //   }
  //   if (step1 == "5") {
  //     setAtual("perfil")
  //   }
  // }, [navigate]);

  return (
    <>

      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: 'flex', backgroundColor: FUNDO_BRANCO, width: "100%" }}>
          <CssBaseline />

          {/* menu */}
          {atual === "configuracao" ? <></> :
            <Drawer variant="permanent" open={open} >

              <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1] }}>
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
                </IconButton>
              </Toolbar>

              {open ? <>
                <img src={ggrowthLogo} alt="Logo" width={120} height={82} style={{ marginLeft: 40, marginBottom: 30 }} />

                <List component="nav"
                  sx={{
                    // hover states
                    '& .MuiListItemButton-root:hover': {
                      bgcolor: FUNDO_BRANCO,
                      '&, & .MuiListItemIcon-root': {
                        color: LARANJA,
                      },
                    },
                  }}>


                  <ListItemButton onClick={() => setAtual("")} style={{
                    marginLeft: 20,
                    borderTopRightRadius: 0, borderBottomRightRadius: 0,
                    borderTopLeftRadius: 25, borderBottomLeftRadius: 25
                  }}>
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" onClick={() => setAtual("")} style={{ marginLeft: -20 }} />
                  </ListItemButton>

                  <ListItemButton onClick={() => setAtual("otimizacao")} style={{
                    marginLeft: 20,
                    borderTopRightRadius: 0, borderBottomRightRadius: 0,
                    borderTopLeftRadius: 25, borderBottomLeftRadius: 25
                  }}>
                    <ListItemIcon>
                      <ShapeLineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Otimizações" onClick={() => setAtual("otimizacao")} style={{ marginLeft: -20 }} />
                  </ListItemButton>

                  <ListItemButton onClick={() => setAtual("configuracao")} style={{
                    marginLeft: 20,
                    borderTopRightRadius: 0, borderBottomRightRadius: 0,
                    borderTopLeftRadius: 25, borderBottomLeftRadius: 25
                  }}>
                    <ListItemIcon>
                      <ScienceIcon />
                    </ListItemIcon>

                    <ListItemText primary="Onboarding" onClick={() => setAtual("configuracao")} style={{ marginLeft: -20 }} />
                  </ListItemButton>

                  <div style={{ marginBottom: 5, marginTop: 220 }}></div>
                  {/* {secondaryListItems} */}
                  {/* <Grid container justifyContent={'center'}>
                    <Button onClick={() => setAtual("nova-campanha")}
                      style={{ fontSize: 12, color: "#FFF", backgroundColor: LARANJA, borderRadius: 25, marginBottom: 8, width: "90%" }}>
                      Importar campanhas1
                    </Button>
                  </Grid> */}

                </List>
              </> : <></>}

            </Drawer>
          }

          {/* conteudo */}
          <Box
            component="main"
            sx={{
              backgroundColor: FUNDO_BRANCO,
              flexGrow: 0,
              height: '100vh',
              width: "100%",
              overflow: 'auto',
              marginTop: 5,
            }}
          >
            <OtimizacaoContextProvider>
              <OtimizacaoScreen />
            </OtimizacaoContextProvider>
          
          </Box>
        </Box>
      </ThemeProvider>

    </>
  );
}