import { useContext, useState } from 'react';
import '../../App.css';
import { Grid, Paper, Typography } from '@mui/material';
import {
  BRANCO, LARANJA, ROXO,
  inicianteDireita, inicianteDireitaSelecionado, inicianteEsquerda,
  inicianteEsquerdaSelecionado, paperNaoSelecionado, paperSelecionado
} from './styles';
import { useGlobalContext } from '../Context';

export default function Rodape() {
  const { clicked, setClicked, showStep, setShowStep } = useGlobalContext();

  return (
    <>
      {showStep === "" ?
        <Grid item container justifyContent={"center"} style={{ width: 760 }}>
          <Typography style={{ color: "#FFF", fontWeight: 600, fontSize: 18 }}>
            Defina seu nível de maturidade com a G Growth
          </Typography>
        </Grid> :
        <>
        <Grid item>
          <div style={{ width: 700 }}></div>
        </Grid>
        <Grid item container justifyContent={"center"} style={{ width: 760 }}>
          <Typography style={{ color: "#FFF", fontWeight: 600, fontSize: 18 }}>
            Defina seu nível de maturidade com a G Growth
          </Typography>
        </Grid>
        </>
      } :
      <></>
    </>
  );
}
