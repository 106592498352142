import { createContext, useContext, useState } from "react"


interface IContextState {
    clicked: boolean
    setClicked: (value: boolean) => void
    showStep: string
    setShowStep: (value: string) => void
    
}

export const MyGlobalContext = createContext<IContextState>({} as IContextState)

export function useGlobalContext(): IContextState {
    const context = useContext(MyGlobalContext)
    return context
}

export function GlobalContextProvider({ children }: any) {
    const [clicked, setClicked] = useState(false)
    const [showStep, setShowStep] = useState("");

    return (
        <MyGlobalContext.Provider value={{
            clicked, setClicked,
            showStep, setShowStep,
        }}>
            {children}
        </MyGlobalContext.Provider>
    )

}