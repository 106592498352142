import { useState } from 'react';
import '../../App.css';
import { Button, Grid, InputLabel, MenuItem, Paper, Select, Typography, styled } from '@mui/material';
import { useGlobalContext } from '../Context';
import { useNavigate } from 'react-router-dom';


const GGSelect = styled((props: any) => (
  <Select {...props} fullWidth size='small' />
))(({ theme }) => ({
  backgroundColor: '#F69F61',
  color: "#FFF",
  fontWeight: 600,
  fontSize: 12,
}));

export default function Intermediario({ setAtual }: any) {
  const [intermediarioMouseOver, setIntermediarioMouseOver] = useState(false);
  const { clicked, setClicked, showStep, setShowStep } = useGlobalContext();
  const navigate = useNavigate();


  const ROXO = '#7822DB';
  const LARANJA = '#F69F61';

  const intermediarioEsquerda = {
    height: "70px",
    width: "60px",
    backgroundColor: ROXO,
    borderTopLeftRadius: "20px",
  };
  const intermediarioDireita = {
    height: "70px",
    width: "60px",
    backgroundColor: LARANJA,
    borderBottomRightRadius: "20px",
  };

  const paperNaoSelecionado = {
    alignItems: "center",
    borderRadius: 10,
    marginRight: 40,
    width: 250,
    height: 300,
    padding: 20
  };
  const paperSelecionado = {
    alignItems: "center",
    borderRadius: 10,
    marginRight: 40,
    width: 250,
    height: 340,
    padding: 20,
    marginTop: -20,
    cursor: "pointer",
    backgroundColor: LARANJA,
    color: "#FFF",
  };

  const intermediarioEsquerdaSelecionado = {
    height: "70px",
    width: "60px",
    backgroundColor: LARANJA,
    borderTopLeftRadius: "20px",
    border: "6px solid",
  };

  const intermediarioDireitaSelecionado = {
    height: "70px",
    width: "60px",
    backgroundColor: "#FFF",
    borderBottomRightRadius: "20px",
  };

  const selectIntermediario = () => {
    if (showStep === "INTERMEDIARIO") {
      setShowStep("");
      setClicked(false);
      setIntermediarioMouseOver(false);
    } else {
      setClicked(clicked);
      setShowStep("INTERMEDIARIO");
    }
  }

  const next = () => {
    setAtual("otimizacao");
  }

  return (
    <>
      {!clicked && (showStep === "INTERMEDIARIO" || showStep === "") ?
        <Grid item
          onClick={selectIntermediario}
          onMouseOver={() => setIntermediarioMouseOver(true)}
          onMouseLeave={() => setIntermediarioMouseOver(false)}>
          <Paper style={intermediarioMouseOver || showStep === "INTERMEDIARIO" ? paperSelecionado : paperNaoSelecionado}>

            <Grid container style={{ marginLeft: 40 }}>
              {showStep === "INTERMEDIARIO" ? 
                <div  onClick={selectIntermediario}>voltar</div> : 
                <></>}
              <Grid container spacing={1} >
                <Grid item style={{
                  height: "130px",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center"
                }}>
                  <div style={intermediarioMouseOver || showStep === "INTERMEDIARIO" ? intermediarioEsquerdaSelecionado : intermediarioEsquerda} />
                </Grid>
                <Grid item style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center"
                }}>
                  <div style={intermediarioMouseOver || showStep === "INTERMEDIARIO" ? intermediarioDireitaSelecionado : intermediarioDireita} />
                </Grid>
              </Grid>

              <Grid container style={{ marginTop: 4 }}>
                <Typography style={
                  intermediarioMouseOver || showStep === "INTERMEDIARIO" ?
                    { color: "#FFF", fontSize: 18, fontWeight: 700 } :
                    { color: ROXO, fontSize: 18, fontWeight: 700 }
                }>
                  INTERMEDIÁRIO
                </Typography>
              </Grid>

              {/* texto */}
              <Grid container>
                <Grid item>
                  <Typography style={{ fontSize: 12, width: 160, textAlign: "center", marginLeft: -20 }}>
                    Consulte as dicas flutuantes para obter o melhor desempenho
                  </Typography>
                </Grid>
              </Grid>

            </Grid>
          </Paper>
        </Grid> :
        <></>
      }
      {showStep === "INTERMEDIARIO" ?
        <div>
          <Paper style={{ 
              marginLeft: -40, width: 600, height: 300, 
              backgroundColor: "#FFF", borderRadius: 0, 
              borderTopRightRadius: 20, borderBottomRightRadius: 20,
              padding: 20 }}>

            <Grid container>
              <Grid item lg={12} md={12} xs={12}>
                <Typography style={{ textTransform: 'uppercase', fontWeight: 700, }}>
                  Configurações da conta
                </Typography>
              </Grid>
              <Grid  item lg={12} md={12} xs={12}>
                <Typography style={{ fontSize: 14}}>
                  Queremos entender sua rotina de Growth!
                </Typography>
              </Grid>

              <Grid  item lg={12} md={12} xs={12}>
                <Typography  style={{ fontSize: 14}}>
                Responda as perguntas para melhorar a configuração da plataforma.
                </Typography>
              </Grid>

              {/* primeira pergunta */}
              <Grid container item lg={12} md={12} xs={12} style={{ marginTop: 6 }} >
                <Grid item lg={9}  style={{ fontSize: 14, fontWeight: 700 }}>
                  Quantas contas de anúncio você gerencia?
                </Grid>
                <Grid item lg={3}>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <GGSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    // onChange={handleChange}
                  >
                    <MenuItem value={1}>De 5 a 10 contas</MenuItem>
                  </GGSelect>
                </Grid>
              </Grid>

              {/* segunda pergunta */}
              <Grid container item lg={12} md={12} xs={12} style={{ marginTop: 6 }} >
              <Grid item lg={9}  style={{ fontSize: 14, fontWeight: 700 }}>
                  Em média, quantas campanhas você gerencia por mês?
                </Grid>
                <Grid item lg={3}>
                  <InputLabel id="demo-simple-select-label2"></InputLabel>
                  <GGSelect
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select"
                    // value={age}
                    // onChange={handleChange}
                  >
                    <MenuItem value={1}>30 ou mais</MenuItem>
                  </GGSelect>
                </Grid>
              </Grid>

              {/* terceira pergunta */}
              <Grid container item lg={12} md={12} xs={12} style={{ marginTop: 6 }} >
              <Grid item lg={9}  style={{ fontSize: 14, fontWeight: 700 }}>
                Quantas otimizações por campanha você faz?
                </Grid>
                <Grid item lg={3}>
                  <InputLabel id="demo-simple-select-label2"></InputLabel>
                  <GGSelect
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select"
                    // value={age}
                    // onChange={handleChange}
                  >
                    <MenuItem value={1}>7 otimizações</MenuItem>
                  </GGSelect>
                </Grid>
              </Grid>

              {/* quarta pergunta */}
              <Grid container item lg={12} md={12} xs={12} style={{ marginTop: 6 }} >
                <Grid item lg={9} style={{ fontSize: 14, fontWeight: 700 }}>
                  Quantos testes A/B você costuma realizar por campanha?
                </Grid>
                <Grid item lg={3}>
                  <InputLabel id="demo-simple-select-label2"></InputLabel>
                  <GGSelect
                    labelId="demo-simple-select-label2"
                    id="demo-simple-select"
                  // value={age}
                  // onChange={handleChange}
                  >
                    <MenuItem value={1}>10 ou mais</MenuItem>
                  </GGSelect>
                </Grid>
              </Grid>

              {/* botao salvar e continuar*/}
              <Grid container item lg={12} md={12} xs={12} >
                <Grid item lg={9} style={{ fontWeight: 700, marginTop: 8 }}>
                  <Button 
                    onClick={next}
                    style={{ backgroundColor: ROXO, color: "#FFF", fontSize: 11 }} size='small'> 
                    salvar e continuar
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
        </div> :
        <></>}
    </>

  );
}
