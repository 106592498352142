import React, { useEffect, useState } from 'react';
import './App.css';
import logo from '../src/ggrowth-background.png';
import { Backdrop, Button, CircularProgress, CssBaseline, Grid, Input, InputAdornment, InputLabel, Paper, TextField, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { AccountCircle } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';

// ENV=dev
const web = "https://localhost:3000";
const api = "http://localhost:3333";
// ENV=prod
// const web = "https://web.ggrowth.com.br";
// const api = "https://api.ggrowth.com.br"

// const stringifiedParams = queryString.stringify({
//   client_id: "820426228801-j6jd6d44l21lnmtkbhjuetaq4tm27upj.apps.googleusercontent.com",
//   redirect_uri: web,
//   scope: [
//     'https://www.googleapis.com/auth/userinfo.email',
//     'https://www.googleapis.com/auth/userinfo.profile',
//     'https://www.googleapis.com/auth/adwords'
//   ].join(' '),
//   response_type: 'code',
//   access_type: 'offline',
//   prompt: 'consent',
// });

// const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?${stringifiedParams}`;

function LoginPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [accessToken, setAccessToken] = useState("");
  const [loggedUser, setLoggedUser] = useState("");
  const [loggedUserEmail, setLoggedUserEmail] = useState("");
  const [showBackdrop, setShowBackdrop] = useState(false);

  const ROXO = '#7822DB';
  const LARANJA = '#F69F61';


  const navigate = useNavigate();


  useEffect(() => {
    const getToken = async () => {
      
      const ggUserSession = localStorage.getItem("gg.user.session");
      if (ggUserSession) {
        navigate("/portal?step=4");
      }
      // const code = searchParams.get("code");

      // if (code) {
      //   setShowBackdrop(true);
      //   const accessToken = "ggtoken001";
      //   sessionStorage.setItem("token", accessToken);
      //   setAccessToken(accessToken);

      //   await getUser();
      // }
    }
    try {
      getToken();
    } catch (e) {
      console.log(e);
    } finally {
      setShowBackdrop(false);
    }

  }, []);

  // async function getUser() {

  //   const tokeen = sessionStorage.getItem("token");
  //   const { data } = await axios({
  //     url: `${api}/api/v1/sso/user`,
  //     method: 'get',
  //     headers: {
  //       Authorization: `Bearer ${tokeen}`,
  //     },
  //   });
  //   setLoggedUser(data.user);
  //   setLoggedUserEmail(data.email);

  //   const userSession = {
  //     email: data.email,
  //     nome: data.user,
  //     gg_token: data.ggtoken,
  //     fa: data.fa
  //   };
  //   localStorage.setItem("gg.user.session", JSON.stringify(userSession));

  //   navigate("/portal?step=4");

  // };

  // async function getAccessTokenFromCode(code: string) {
  //   const { data } = await axios({
  //     url: `${api}/api/v1/sso/token`,
  //     method: 'post',
  //     headers: {
  //       Authorization: `Code ${code}`,
  //     },
  //   });
  //   console.log(data);
  //   return data.access_token;
  // };


  return (
    <div style={{
      backgroundImage: `url(${logo})`, height: "100vh",
      backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"
    }}>
      <CssBaseline />

      <Grid container >

        <Paper style={{ marginLeft: 160, marginTop: 100, padding: 20, borderRadius: 10, width: 260 }}>

          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'}>
            <Typography style={{ marginBottom: 10, color: ROXO, fontSize: 16, fontWeight: 500, textTransform: 'uppercase' }}>
              Entre no GGrowth
            </Typography>

          </Grid>

          {/* login email */}
          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} style={{ marginBottom: 10 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Input fullWidth
                disableUnderline={true}
                placeholder='Usuário'
                style={{ border: "1px solid", borderRadius: 5, borderColor: ROXO, fontSize: 12 }}
                startAdornment={
                  <InputAdornment position="start">
                    <PersonIcon style={{ color: ROXO }} />
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>

          {/* senha */}
          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} >
            <Grid item xs={12} md={12} lg={12}>
              <Input fullWidth
                disableUnderline={true}
                placeholder='Senha'
                style={{ border: "1px solid", borderRadius: 5, borderColor: ROXO, fontSize: 12 }}
                startAdornment={
                  <InputAdornment position="start">
                    <LockIcon style={{ color: ROXO }} />
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>

          {/* botao entrar */}
          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} >
            <Button onClick={() => console.log("log")} 
              style={{
                backgroundColor: ROXO, color: "#FFF", width: "100%",
                marginTop: 10, height: "30px", fontSize: "10px"
              }}>
              Entrar
            </Button>
          </Grid>

          {/* google oauth */}
          {/* <a href={googleLoginUrl}>
            <Grid item xs={12} md={12} lg={12} container justifyContent={'center'}>
              <Button
                // onClick={() => navigate("/portal?step=4")}
                style={{
                  height: "34px", textTransform: "none", marginTop: 10,
                  border: "1px solid rgb(255, 92, 136)", color: "#000", fontSize: "12px",
                  marginBottom: 20,
                }}>
                <svg width="25" height="25" viewBox="5 5 35 35" xmlns="http://www.w3.org/2000/svg"
                  style={{ height: "32px", width: "32px", marginLeft: "-8px" }}>
                  <g fill="none" fill-rule="evenodd">
                    <path d="M31.64 23.205c0-.639-.057-1.252-.164-1.841H23v3.481h4.844a4.14 4.14 0 0 1-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z" fill="#4285F4"></path><path d="M23 32c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711h-3.007v2.332A8.997 8.997 0 0 0 23 32z" fill="#34A853"></path><path d="M17.964 24.71a5.41 5.41 0 0 1-.282-1.71c0-.593.102-1.17.282-1.71v-2.332h-3.007A8.996 8.996 0 0 0 14 23c0 1.452.348 2.827.957 4.042l3.007-2.332z" fill="#FBBC05"></path><path d="M23 17.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C27.463 14.891 25.426 14 23 14a8.997 8.997 0 0 0-8.043 4.958l3.007 2.332c.708-2.127 2.692-3.71 5.036-3.71z" fill="#EA4335">
                    </path>
                    <path d="M14 14h18v18H14V14z">
                    </path>
                  </g>
                </svg>
                Continuar com o Google
              </Button>
            </Grid>
          </a> */}
          {/* cadastrar */}
          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} >
            <Typography style={{ fontSize: 12, marginTop: 20, color: "#888" }}>
              Não tem uma conta? <span style={{ cursor: "pointer " }} onClick={() => navigate("/cadastro")}>Cadastre-se!</span>
            </Typography>
          </Grid>

          {/* recuperar senha */}
          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} >
            <Typography style={{ fontSize: 12, marginTop: 5, color: "#888" }}>
              Esqueceu a senha? Recupere-a!
            </Typography>
          </Grid>

        </Paper>
      </Grid>
      <Backdrop open={showBackdrop} style={{ zIndex: 9999, backgroundColor: "rgba(246, 159, 97, 0.9)" }}>
        <div>
          <CircularProgress style={{ color: "#FFF", width: 60, height: 60 }} />
        </div>
      </Backdrop>

    </div>
  );
}

export default LoginPage;
