import { useState } from 'react';
import '../../App.css';
import { Grid, Paper, Typography } from '@mui/material';
import { useGlobalContext } from '../Context';


export default function Avancado({ setAtual }: any) {
  const [avancadoMouseOver, setAvancadoMouseOver] = useState(false);
  const { clicked, setClicked, showStep, setShowStep } = useGlobalContext();


  const ROXO = '#7822DB';
  const LARANJA = '#F69F61';

  const avancadoEsquerda = {
    height: "90px",
    width: "60px",
    backgroundColor: ROXO,
    borderTopLeftRadius: "20px",
  };
  const avancadoDireita = {
    height: "40px",
    width: "60px",
    backgroundColor: LARANJA,
    borderBottomRightRadius: "20px",
  };

  const paperNaoSelecionado = {
    alignItems: "center",
    borderRadius: 10,
    marginRight: 40,
    width: 250,
    height: 300,
    padding: 20
  };
  const paperSelecionado = {
    alignItems: "center",
    borderRadius: 10,
    marginRight: 40,
    width: 250,
    height: 340,
    padding: 20,
    marginTop: -20,
    cursor: "pointer",
    backgroundColor: LARANJA,
    color: "#FFF",
  };

  const avancadoEsquerdaSelecionado = {
    height: "90px",
    width: "60px",
    backgroundColor: LARANJA,
    borderTopLeftRadius: "20px",
    border: "6px solid",
  };
  const avancadoDireitaSelecionado = {
    height: "40px",
    width: "60px",
    backgroundColor: "#FFF",
    borderBottomRightRadius: "20px",
  };


  const selectAvancado = () => {
    setClicked(clicked);
    setShowStep("AVANCADO");
  }

  return (
    <>
      {!clicked && (showStep === "AVANCADO" || showStep === "") ?
        <Grid item 
          onClick={selectAvancado}
          onMouseOver={() => setAvancadoMouseOver(true)} 
          onMouseLeave={() => setAvancadoMouseOver(false)}>
          <Paper style={avancadoMouseOver  || showStep === "AVANCADO" ? paperSelecionado : paperNaoSelecionado}>

            <Grid container style={{ marginLeft: 40 }}>
              <Grid container spacing={1} >
                <Grid item style={{
                  height: "130px",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center"
                }}>
                  <div style={avancadoMouseOver  || showStep === "AVANCADO"? avancadoEsquerdaSelecionado : avancadoEsquerda} />
                </Grid>
                <Grid item style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center"
                }}>
                  <div style={avancadoMouseOver  || showStep === "AVANCADO"? avancadoDireitaSelecionado : avancadoDireita} />
                </Grid>
              </Grid>

              <Grid container style={{ marginTop: 4 }}>
                <Typography style={
                  avancadoMouseOver  || showStep === "AVANCADO"?
                    { color: "#FFF", fontSize: 18, fontWeight: 700 } :
                    { color: ROXO, fontSize: 18, fontWeight: 700 }
                }>
                  AVANÇADO
                </Typography>
              </Grid>

              {/* texto */}
              <Grid container>
                <Grid item>
                  <Typography style={{ fontSize: 12, width: 160, textAlign: "center", marginLeft: -20 }}>
                    Gerencie suas campanhas e testes livremente
                  </Typography>
                </Grid>
              </Grid>

            </Grid>
          </Paper>
        </Grid> :
        <></>
      }
    </>

  );
}
