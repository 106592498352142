import React from 'react';
import '../App.css';
import logo from '../ggrowth-background-2.png';
import { Button, CssBaseline, Grid, Input, InputAdornment, InputLabel, Paper, TextField, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { AccountCircle } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import BadgeIcon from '@mui/icons-material/Badge';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

export default function Cadastro() {
  const ROXO = '#7822DB';
  const LARANJA = '#F69F61';

  const navigate = useNavigate();


  return (
    <div style={{
      backgroundImage: `url(${logo})`, height: "100vh", backgroundSize: "cover",
      backgroundPosition: "center", backgroundPositionY: -200
    }}>
      <CssBaseline />

      <Grid container >

        <Paper style={{ marginLeft: 160, marginTop: 100, padding: 20, borderRadius: 10, width: 260 }}>

          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'}>
            <Typography style={{ marginBottom: 10, color: ROXO, fontSize: 16, fontWeight: 500, textTransform: 'uppercase' }}>
              Entre no GGrowth
            </Typography>

          </Grid>

          {/* nome */}
          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} style={{ marginBottom: 10 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Input fullWidth
                disableUnderline={true}
                placeholder='Nome'
                style={{ border: "1px solid", borderRadius: 5, borderColor: ROXO, fontSize: 12 }}
                startAdornment={
                  <InputAdornment position="start">
                    {/* <PersonIcon style={{ color: ROXO }} /> */}
                    <BadgeIcon  style={{ color: ROXO }}  />
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>

          {/* email */}
          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} style={{ marginBottom: 10 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Input fullWidth
                disableUnderline={true}
                placeholder='E-mail'
                style={{ border: "1px solid", borderRadius: 5, borderColor: ROXO, fontSize: 12 }}
                startAdornment={
                  <InputAdornment position="start">
                    <AlternateEmailIcon style={{ color: ROXO }} />
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>

           {/* usuario */}
           <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} style={{ marginBottom: 10 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Input fullWidth
                disableUnderline={true}
                placeholder='Usuário'
                style={{ border: "1px solid", borderRadius: 5, borderColor: ROXO, fontSize: 12 }}
                startAdornment={
                  <InputAdornment position="start">
                    <PersonIcon style={{ color: ROXO }} />
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>

          {/* senha */}
          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} style={{ marginBottom: 10 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Input fullWidth
                disableUnderline={true}
                placeholder='Senha'
                style={{ border: "1px solid", borderRadius: 5, borderColor: ROXO, fontSize: 12 }}
                startAdornment={
                  <InputAdornment position="start">
                    <LockIcon style={{ color: ROXO }} />
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>

           {/* confirmar senha */}
           <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} style={{ marginBottom: 10 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Input fullWidth
                disableUnderline={true}
                placeholder='Confirme sua senha'
                style={{ border: "1px solid", borderRadius: 5, borderColor: ROXO, fontSize: 12 }}
                startAdornment={
                  <InputAdornment position="start">
                    <LockIcon style={{ color: ROXO }} />
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>

      
          {/* termos */}
          <Grid item xs={12} md={12} lg={12} container justifyContent={'left'} >
            <Typography style={{ fontSize: 12, marginTop: 10, color: "#888" }}>
              Aceitar termos de serviço
            </Typography>
          </Grid>

          {/* termos */}
          <Grid item xs={12} md={12} lg={12} container justifyContent={'left'} >
            <Typography style={{ fontSize: 12, marginTop: 5, color: "#888" }}>
            Aceitar política de proteção de dados
            </Typography>
          </Grid>

            {/* botao entrar */}
            <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} >
            <Button style={{
              backgroundColor: ROXO, color: "#FFF", width: "100%",
              marginTop: 10, height: "30px", fontSize: "10px"
            }}>
              Salvar e continuar
            </Button>
          </Grid>


        </Paper>
      </Grid>

    </div>
  );
}

