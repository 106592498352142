export const initFacebookSdk = () => {
    console.log("initFacebookSdk")
    return new Promise((resolve, reject) => {
        // console.log("Load the Facebook SDK asynchronously")
        // Load the Facebook SDK asynchronously
        window.fbAsyncInit = () => {
            // console.log("window.fbAsyncInit")
            window.FB.init({
                // appId: process.env.REACT_APP_FB_ID,
                appId: '225508010255251',
                cookie: true,
                xfbml: true,
                version: 'v18.0'
            });
            // Resolve the promise when the SDK is loaded
            resolve();
        }
        console.log("Waiting...?")
    })
}

export const getFacebookLoginStatus = () => {
    console.log("getFacebookLoginStatus");
    return new Promise((resolve, reject) => {
        window.FB.getLoginStatus((response) => {
            console.log(response);
            resolve(response);
        });
    });
};

export const fbLogin = () => {
    console.log("fbLogin");
    return new Promise((resolve, reject) => {
        window.FB.login((response) => {
            resolve(response)
        }, 
        { scope: 'ads_read, leads_retrieval, page_events, pages_read_engagement, pages_show_list, read_insights' }
        )
    }).catch((error) => {
        console.log("error");
        console.log(error);
    })
}