import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Cadastro from './Cadastro';
import Portal from './Portal/Portal';
import Vinculo from './Vinculo';
import LoginPage from './LoginPage';
import Maturidade from './Maturidade';
import Verificacao from './Cadastro/Verificacao';
import VinculoFacebook from './VinculoFacebook';
import BasicTabs from './OtimizacaoScreen/BasicTabs';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Portal />} /> */}
        {/* <Route path="/" element={<BasicTabs />} /> */}
        <Route path="/" element={<Portal />} />
        {/* <Route path="/" element={<LoginPage />} /> */}
        <Route path="/portal" element={<Portal />} />
        <Route path="/cadastro" element={<Cadastro />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/m" element={<Maturidade />} />
        <Route path="/verifique" element={<Verificacao />} />
        <Route path="/fb" element={<VinculoFacebook />} />
        {/* <Route path="/vinculo" element={<Vinculo />} /> */}
        {/* <Route path="/otimizacao" element={<Otimizacao />} />
        <Route path="/dashboard" element={<Dashboard />} /> */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

