import { useEffect, useState } from "react";
import {
  getFacebookLoginStatus,
  initFacebookSdk,
  fbLogin,
} from "./utils/FacebookSDK";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Grid, Paper, Typography } from "@mui/material";

export default function VinculoFacebook() {
  const [fbLogged, setfbLogged] = useState(false);
  const [userId, setUserId] = useState("");

  // ENV=dev
  const web = "https://localhost:3000";
  const api = "http://localhost:3333";
  // ENV=prod
  // const web = "https://web.ggrowth.com.br";
  // const api = "https://api.ggrowth.com.br"

  useEffect(() => {

    const userSession = localStorage.getItem("gg.user.session");
    console.log(userSession);

    getFacebookLoginStatus().then((response) => {
      console.log(response);
      if (response.status === "connected") {
        console.log("Person is connected");
        setfbLogged(true);
        console.log("response.userId e accessToken");
        console.log(response.authResponse.userID);
        console.log(response.authResponse.accessToken);
        
        // todo: chamar API para informacoes do FB User
      }
    });


  }, []);

  function login() {
    fbLogin().then((response) => {
      console.log(response);
      if (response && response.status === "connected") {
        console.log("Person2 is connected");
        setfbLogged(true);
        console.log(response);
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    <div className="App">
      <div style={{ marginBottom: 10 }}>
        Perfil
      </div>

      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', m: 5 }} >
        <Grid container item xs={12}>
          <Grid item md={12}>
            Nome: Fulano de Tal
          </Grid>
          <Grid item md={12}>
            E-mail: fulano@teste.com
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', m: 5 }}>
        <Grid item md={12}>
          <div>Facebook</div>
          { fbLogged ?
            <div>
              <div style={{ marginBottom: 30 }}>
                Você está logado!<br />
                <div>
                  FB UserID:
                </div>
                <div>
                  FB Token:
                </div>
                <div>
                  FB Contas de anúncio:
                </div>
              </div>
            </div> :
            <div>
              <button style={{ height: 30, backgroundColor: "#FFF", border: 0, cursor: "pointer" }} onClick={login}>
                Login with Facebook
              </button>
            </div>
          }
        </Grid>
      </Paper>

      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', m: 5 }}>
        <Grid>
          <div>Google</div>
          <button style={{ height: 30, backgroundColor: "#FFF", border: 0, cursor: "pointer" }}>
            Login with Google
          </button>
        </Grid>
      </Paper>
      <br />

    </div>
  );
}