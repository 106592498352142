import React, { useState } from 'react';
import axios from 'axios';
import { useOtimizacaoScreenContext } from './OtimizacaoScreenContext';
import { LARANJA } from '../Cadastro/styles';
import { Button, Hidden, Input } from '@mui/material';
import { CloudUploadOutlined } from '@mui/icons-material';

const FileUpload = () => {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const { campanhas, setCampanhas } = useOtimizacaoScreenContext();

    // Função para lidar com a seleção do arquivo
    const handleFileChange = (e: any) => {
        setFile(e.target.files[0]);
    };

    // Função para enviar o arquivo para o servidor
    const handleUpload = async () => {
        if (!file) {
            setMessage('Por favor, selecione um arquivo.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        // const api = "http://localhost:3333"; // DEV
        const api = "https://api.ggrowth.com.br"; // PROD

        try {
            // Envia o arquivo para a API usando Axios
            const response = await axios.post(`${api}/api/v1/campanhas/upload-csv`,
                formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setMessage('');
            setCampanhas(response.data.data);
            console.log('Resposta da API:', response.data.data);
        } catch (error) {
            setMessage('Erro ao fazer upload do arquivo.');
            console.error('Erro:', error);
        }
    };

    return (
        <>
            <Button >
                <Input type="file" onChange={handleFileChange} 
                    style={{ marginLeft: 10 }}/>
            </Button>

            <Button
                style={{
                    fontSize: 12, color: "#FFF", backgroundColor: LARANJA,
                    borderRadius: 25, marginBottom: 8, width: "200px"
                }}
                onClick={handleUpload}>Importar campanhas
            </Button>
            {message &&  <p>{message}</p>}
        </>
    );
};

export default FileUpload;
