import { Grid, Typography, Avatar } from "@mui/material";

interface TProps {
  valor: string
}

export default function Titulo(valor: TProps) {
    return (
      <>
        <Grid item xs={12} md={6} style={{ padding: 10}}>
          <Typography style={{ marginTop: 5, textTransform: 'none', fontWeight: 600, fontSize: 12, color: "#000" }}>
            {valor.valor}
          </Typography>
        </Grid>
      </>
  
    )
  }