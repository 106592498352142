export const ROXO = '#7822DB';
export const LARANJA = '#F69F61';
export const BRANCO = "#FFF";

export const paperNaoSelecionado = {
  alignItems: "center",
  borderRadius: 10,
  marginRight: 40,
  marginLeft: 40, 
  width: 220,
  height: 300,
  padding: 20,
  backgroundColor: ROXO,
  color: "#FFF"
};
export const paperSelecionado = {
  alignItems: "center",
  borderRadius: 10,
  marginRight: 40,
  marginLeft: 40, 
  width: 220,
  height: 300,
  padding: 20,
  cursor: "pointer",
  backgroundColor: LARANJA,
  color: "#FFF",
};

export const inicianteEsquerdaSelecionado = {
  height: "30px",
  width: "60px",
  backgroundColor: LARANJA,
  borderTopLeftRadius: "20px",
  border: "6px solid",
};

export const inicianteDireitaSelecionado = {
  height: "60px",
  width: "60px",
  backgroundColor: "#FFF",
  borderBottomRightRadius: "20px",
};