import { Grid  } from '@mui/material';
import Titulo from './Titulo';
import IndicadoresChips from './IndicadoresChips';
import { AccordionCampanha, AccordionCampanhaDetalhes, AccordionCampanhaResumo } from './components/Accordion';
import { ICampanha } from '..';
import DiscreteSliderValues from './components/DiscreteSliderValues';

interface TProps {
  campanha: ICampanha
}

export default function CampanhaResumo(props: TProps) {
  const classificacaoQualidade = props.campanha.indicadoresCheckboxes[0].valor;
  const classificacaoTaxaEngajamento = props.campanha.indicadoresCheckboxes[1].valor;
  const classificacaoTaxaConversao = props.campanha.indicadoresCheckboxes[2].valor;

  // colunas ESQ
  const roas = props.campanha.indicadoresCheckboxes[3].valor;
  const impressoes = props.campanha.indicadoresCheckboxes[4].valor;
  const frequencia = props.campanha.indicadoresCheckboxes[5].valor;

  // colunas DIR
  const custoPorResultado = props.campanha.indicadoresCheckboxes[6].valor;
  const custoPorRegistroConcluido = props.campanha.indicadoresCheckboxes[7].valor;
  const custoPorMilContasAlcancadas = props.campanha.indicadoresCheckboxes[8].valor;

  return (
    <AccordionCampanha style={{ boxShadow: "1px 1px rgba(0, 0, 0, .3)", marginBottom: 10 }}>
      {/* BARRA SUPERIOR */}
      <AccordionCampanhaResumo>
        <Grid container >
          <Grid item md={4} container>
            <Titulo valor={props.campanha.titulo} />
          </Grid>

          <Grid item md={8} style={{ padding: 10 }}>
           
            <IndicadoresChips chip={props.campanha.indicadoresChips} />
          </Grid>
        </Grid>
      </AccordionCampanhaResumo>

      {/* CONTEUDO */}
      <AccordionCampanhaDetalhes>
        <Grid container spacing={1} style={{ marginTop: 0 }}>
          <Grid container item md={12} spacing={2}>
            <Grid item md={4} >
              <div style={{ border: "1px solid #7822DB", borderRadius: '10px', boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                <span style={{ marginLeft: 8, fontStyle: 'italic', fontWeight: 'bold', fontSize: 14 }}>
                  Classificacao Qualidade
                </span>
                <div style={{ marginLeft: 4, fontSize: 28, fontWeight: 'bold' }}>
                  {classificacaoQualidade}
                </div>
              </div>
            </Grid>

            <Grid item md={4} >
              <div style={{ border: "1px solid #7822DB", borderRadius: '10px', boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                <span style={{ marginLeft: 8, fontStyle: 'italic', fontWeight: 'bold', fontSize: 14 }}>
                  Classificacao Taxa de Engajamento
                </span>
                <div style={{ marginLeft: 4, fontSize: 28, fontWeight: 'bold' }}>
                  {classificacaoTaxaEngajamento}
                </div>
              </div>
            </Grid>

            <Grid item md={3} >
              <div style={{ border: "1px solid #7822DB", borderRadius: '10px', boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                <span style={{ marginLeft: 8, fontStyle: 'italic', fontWeight: 'bold', fontSize: 14 }}>
                  Classificacao Taxa de Conversão
                </span>
                <div style={{ marginLeft: 8, fontSize: 28, fontWeight: 'bold' }}>
                  {classificacaoTaxaConversao}
                </div>
              </div>
            </Grid>

          </Grid>

          {/* Colunas */}
          <Grid container item md={12} spacing={4}>

            {/* COLUNA ESQUERDA */}
            <Grid container item md={5}>
              {linha("roas", roas)}
              {linha("impressoes", impressoes)}
              {linha("frequencia", frequencia)}
            </Grid>

            {/* COLUNA DIREITA */}
            <Grid container item md={6}>
              {linha("custo por resultado", custoPorResultado)}
              {linha("custo por registro concluído", custoPorRegistroConcluido)}
              {linha("custo por mil contas alcançadas", custoPorMilContasAlcancadas)}
            </Grid>

            {/* <IndicadoresChips chip={props.campanha.indicadoresCheckboxes}/> */}
          </Grid>
        </Grid>
      </AccordionCampanhaDetalhes>

    </AccordionCampanha>
  );
}

function linha(descricao: any, valor: number) {
  return <>
    {/* rotulo */}
    <Grid item md={4}>
      <div style={{ fontStyle: 'italic', fontSize: 14 }}>
        {descricao}
      </div>
    </Grid>

    {/* valor */}
    <Grid item md={4}>
      <div style={{ fontWeight: 'bold', fontSize: 14 }}>
        {valor}
      </div>
    </Grid>

    {/* slider */}
    <Grid item md={4}>
      <DiscreteSliderValues valor={calcularResultadoSilder(descricao, valor)} />
    </Grid>
  </>;
}
function calcularResultadoSilder(descricao: string, valor: number): number {
  console.log(descricao);
  console.log(valor);
  if (descricao.includes('impressoes')) {
    console.log("---- IMPRESSOES");

    if (valor < 2000) {
      console.log("valor < 2000");
      return 10; // ruim
    } else if (valor >= 2000 && valor < 6000) {
      console.log("valor >= 2000 && valor < 6000");
      return 30; // medio
    } else if (valor >= 6000 && valor < 14000) {
      console.log("valor >= 6000 && valor < 14000");
      return 60; // bom
    } else if (valor >= 14000) {
      console.log("valor >= 14000");
      return 90; // otimo
    } 
  }

  if (descricao.includes('roas')) {
    console.log("---- ROAS");

    if (valor < 3) {
      console.log("valor < 3");
      return 10; // ruim
    } else if (valor >= 3 && valor < 5) {
      console.log("valor >= 3 && valor < 5");
      return 30; // medio
    } else if (valor >= 5 && valor < 10) {
      console.log("valor >= 5 && valor < 10");
      return 60; // bom
    } else if (valor >= 10) {
      console.log("valor >= 10");
      return 90; // otimo
    } 
  }

  if (descricao.includes('frequencia')) {
    console.log("---- FREQUENCIA");

    if (valor < 3) {
      console.log("valor < 3");
      return 10; // ruim
    } else if (valor >= 3 && valor < 5) {
      console.log("valor >= 3 && valor < 5");
      return 30; // medio
    } else if (valor >= 5 && valor < 10) {
      console.log("valor >= 5 && valor < 10");
      return 60; // bom
    } else if (valor >= 10) {
      console.log("valor >= 10");
      return 90; // otimo
    } 
  }

  if (descricao.includes('custo por resultado')) {
    console.log("---- custo por resultado");

    if (valor > 21) {
      console.log("valor < 10");
      return 10; // ruim
    } else if (valor >= 15 && valor < 20) {
      console.log("valor >= 15 && valor < 20");
      return 30; // medio
    } else if (valor >= 11 && valor < 15) {
      console.log("valor >= 11 && valor < 15");
      return 60; // bom
    } else if (valor < 10) {
      console.log("valor < 10");
      return 90; // otimo
    } 
  }

  if (descricao.includes('custo por registro')) {
    console.log("---- custo por registro");

    if (valor > 21) {
      console.log("valor > 21");
      return 10; // ruim
    } else if (valor >= 11 && valor < 20) {
      console.log("valor >= 15 && valor < 20");
      return 30; // medio
    } else if (valor >= 3 && valor < 10) {
      console.log("valor >= 11 && valor < 15");
      return 60; // bom
    } else if (valor < 3) {
      console.log("valor < 10");
      return 90; // otimo
    } 
  }

  if (descricao.includes('custo por mil contas alcançadas')) {
    console.log("---- custo por mil contas alcançadas");

    if (valor > 0.61) {
      console.log("valor > 21");
      return 10; // ruim
    } else if (valor >= 0.21 && valor < 0.60) {
      console.log("valor >= 15 && valor < 20");
      return 30; // medio
    } else if (valor >= 0.11 && valor < 0.20) {
      console.log("valor >= 11 && valor < 15");
      return 60; // bom
    } else if (valor < 0.10) {
      console.log("valor < 10");
      return 90; // otimo
    } 
  }

  return 0;
}

