import '../App.css';
import logo from '../ggrowth-background-2.png';
import { Button, CssBaseline, Grid, Input, InputAdornment, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PinIcon from '@mui/icons-material/Pin';

export default function Verificacao() {
  const ROXO = '#7822DB';
  const LARANJA = '#F69F61';

  const navigate = useNavigate();


  return (
    <div style={{
      backgroundImage: `url(${logo})`, height: "100vh", backgroundSize: "cover",
      backgroundPosition: "center", backgroundPositionY: -200
    }}>
      <CssBaseline />

      <Grid container >

        <Paper style={{ marginLeft: 160, marginTop: 100, padding: 20, borderRadius: 10, width: 260 }}>

          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'}>
            <Typography style={{ marginBottom: 10, color: ROXO, fontSize: 16, fontWeight: 500, textTransform: 'uppercase' }}>
              Verifique sua conta
            </Typography>

          </Grid>

          {/* nome */}
          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} style={{ marginBottom: 10 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography style={{ color: ROXO, textTransform: "uppercase", fontSize: 12, fontWeight: 700 }}>
                Obrigado pelo seu cadastro!
              </Typography>

              <Typography style={{ fontSize: 14, color: "#000", marginTop: 10, marginBottom: 20, textAlign: "center" }}>
                Em instantes você receberá seu <span style={{ fontWeight: 700 }}>CÓDIGO DE VERIFICAÇÃO.</span>
              </Typography>

              <Typography style={{ fontSize: 14, color: "#000", marginTop: 10, marginBottom: 20, textAlign: "center" }}>
                Insira o código no campo abaixo para começar a 
                 <span style={{ fontWeight: 700 }}> gerenciar seus anúncios com a ajuda do GGrowth!</span>
              </Typography>
            </Grid>
          </Grid>

          {/* email */}
          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} style={{ marginBottom: 10 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Input fullWidth
                disableUnderline={true}
                placeholder='Código de verificação'
                style={{ border: "1px solid", borderRadius: 5, borderColor: ROXO, fontSize: 12 }}
                startAdornment={
                  <InputAdornment position="start">
                    <PinIcon style={{ color: ROXO }} />
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>

          {/* nao recebeu seu codigo */}
          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} style={{ marginBottom: 10 }}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography style={{ fontSize: 14, textAlign: "center"}}>
                Não recebeu seu código?
              </Typography>
              <Typography style={{ fontSize: 12, fontStyle: "italic", color: ROXO, textAlign: "center", textDecoration: "underline"  }}>
                Clique aqui para enviar novamente.
              </Typography>
            </Grid>
          </Grid>


          {/* botao entrar */}
          <Grid item xs={12} md={12} lg={12} container justifyContent={'center'} >
            <Button style={{
              backgroundColor: ROXO, color: "#FFF", width: "100%",
              marginTop: 10, height: "30px", fontSize: "10px"
            }}>
              Entrar
            </Button>
          </Grid>


        </Paper>
      </Grid>

    </div>
  );
}

